import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/fabio2.jpg" // Tell webpack this JS file uses this image
import pal12 from "../images/palestrantes/cabrera.png" // Tell webpack this JS file uses this image

import Footer from "../components/footer"
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import logo3 from "../images/logos/georgia.png" // Tell webpack this JS file uses this image
import logo4 from "../images/logos/texastech.png" // Tell webpack this JS file uses this image
import fotomasterclass from "../images/imagem-masterclass.jpg" // Tell webpack this JS file uses this image
import logo5 from "../images/logos/fmv.png" // Tell webpack this JS file uses this image

import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';

var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
          <div className="texto inter-bold">
             <span className="opacity"> Decisiones económicas<br></br> en las granjas</span> <br></br>
              
            </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
               

                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal12} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Victor Cabrera
                      </div>
                    
                    <div className="titulo inter-light">
                    Professor e especialista em gestão de explorações de leite
                      </div>
                      </div>
                    
                  </div>
              
                </div>
                <div className="col-right inter-regular">
                    <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Tomar, Portugal
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                      29 e 30 de abril
                      </div>
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                      Espanhol
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                      800€ +IVA
                      </div>
                    
                    </div>
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/decidiones-economicas-granjas.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                <div className="form">
                <a href="https://forms.gle/26gp53NrxwMPna4y5" target="_blank" >
                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
                </div>
              </div>
            </div>
          </div>
        
      </div>
    </div>

    <div id="section-2-masterclass">
      <div className="wrapper-fixed">
      <img src={fotomasterclass} alt="" />
        </div>
      <div className="wrapper">
        <div className="wrapper-col-1">
          <div className="wrapper-text">
            <div className="subtitle">
              Descrição
              </div>
            <div className="title inter-black">
              Sobre o Curso
              </div>
              <div className="desc inter-light">
              Esta masterclass de 3 dias visa capacitar profissionais da área de
produção leiteira com ferramentas e conhecimentos de ponta para
otimizar a rentabilidade e sustentabilidade das suas explorações.
Abordaremos temas como o uso de IA e ferramentas de apoio à decisão
para melhorar a lucratividade, princípios de abate e controlo de mastite,
e o projeto Dairy Brain para tomada de decisão mais eficaz.
Exploraremos também o modelo DairyPrint para reduzir o impacto
ambiental, a eficiência alimentar e alimentação de precisão, e a previsão
de resultados com base na demografia do rebanho. O curso inclui
palestras, discussões aprofundadas e prática com ferramentas e
modelos, com foco na interação e participação ativa dos participantes.
                </div>
                <div className="logos">
                  
                {/* <img src={logo5} alt="" />
                <img src={logo2} alt="" /> */}

                  </div>
            </div>
          </div>
          <div className="wrapper-col-2">
          </div>
      </div>
    

    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
   
      </div>
      <div className="wrapper-program">
      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                        <Accordion.Header>
                              
                            + Dia 1 - 29/04/2025
                            </Accordion.Header>

                            <Accordion.Body>
                               
                  
                                <div class="modulo">
                                  <p><span className="date">09:00 - 11:00</span> | <span className="mod">Módulo 1</span> - <span class="txt">Mejorar la rentabilidad de las granjas
lecheras utilizando herramientas de apoyo
a la toma de decisiones e IA</span></p>
                                </div>
        
                             
                                <div class="modulo">
                                  <p><span className="date">11:00 - 11:30</span> | <span className="mod">Coffee break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:30 - 13:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Eficiencia alimentaria de vacas lecheras y alimentación de
                                  precisión</span></p>
                                </div>
                      
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:00</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:00 - 15:30</span> | <span className="mod">Módulo 3</span> - <span class="txt">Mejorar la precisión nutricional</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">15:30 - 16:00</span> | <span className="mod">Coffee break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:00 - 17:30</span> | <span className="mod">Módulo 4 </span> - <span class="txt">Marcadores económicos del desempeño reproductivo lechero</span></p>
                                </div>
                       
          
                    

                                
                                </Accordion.Body>

                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                        <Accordion.Header>
                              
                            + Dia 2 - 30/04/2025
                            </Accordion.Header>

                            <Accordion.Body>
                               
                  
                               <div class="modulo">
                                 <p><span className="date">09:00 - 11:00</span> | <span className="mod">Módulo 1</span> - <span class="txt">Principios del sacrificio y su
                                 aplicación a los casos de mastitis</span></p>
                               </div>
       
                            
                               <div class="modulo">
                                 <p><span className="date">11:00 - 11:30</span> | <span className="mod">Coffee break</span></p>
                               </div>
                               <div class="modulo">
                                 <p><span className="date">11:30 - 13:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">El proyecto Dairy Brain y su aplicación para una mejor toma
                                 de decisiones en las granjas lecheras</span></p>
                               </div>
                     
                               <div class="modulo">
                                 <p><span className="date">13:00 - 14:00</span> | <span className="mod">Almoço</span></p>
                               </div>
                               <div class="modulo">
                                 <p><span className="date">14:00 - 15:30</span> | <span className="mod">Módulo 3</span> - <span class="txt">El modelo DairyPrint y cómo ayudar a los productores de
                                 leche a disminuir la huella ambiental</span></p>
                               </div>
                               <div class="modulo">
                                 <p><span className="date">15:30 - 16:00</span> | <span className="mod">Coffee break</span></p>
                               </div>
                               <div class="modulo">
                                 <p><span className="date">16:00 - 17:30</span> | <span className="mod">Módulo 4 </span> - <span class="txt">Inteligencia artificial para la gestión ganadera</span></p>
                               </div>
                      
         
                   

                               
                               </Accordion.Body>

                                </Accordion.Item>

                               
                           
                        

                    </Accordion>
                    </div>
        <div className="wrapper-btns">
        <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/decidiones-economicas-granjas.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button>
                </a> 
                </div>
                <div className="form">
                <a href="https://forms.gle/26gp53NrxwMPna4y5" target="_blank" >

                <button className="btn-form inter-bold">Inscrição</button> 

                </a>
          </div>
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
            </div>
      </div>
      <div className="wrapper-speakers">
      

        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal12} alt="" />

              </div>
              <div className="nome">
                Victor Cabrera
              </div>
              <div className="titulo">
              Professor Universitário e Investigador Welfare Quality
                </div>
          </div>
          <div className="body">
          <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span>Professor na University of Wisconsin-Madison, EUA
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Especialista em gestão de explorações leiteiras,
desenvolvendo modelos e técnicas de aumento da eficiência
na produção
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span>

                  Desenvolveu mais de 50 ferramentas de apoio à decisão para
a indústria agrícola</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
                  Editor Associado da Frontiers in Animal Science - Precision
Livestock Farming
</p>
          
               
                  </div>
              </div>
            </div>
        </div>
        </div>
   
      </div>
     

    </div>

    <div id="section-fotos">
      <Slider {...settings}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    {/* <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div> */}
    <Footer/>

  </Layout>
)

export default wqmatadouros
